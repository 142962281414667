<template>
    <div>
        <DynamicStat v-for="stat of stats" :key="stat.id" v-bind:stat="stat" />
    </div>
</template>

<script>
export default {
    props: {
        role: { type: String, required: true },
    },
    components: {
        DynamicStat: () => import('@/components/DynamicStat'),
    },
    computed: {
        stats() {
            const stats = this.$store.getters['stats/getStatsByRole'](this.role)
            return stats
        },
    },
}
</script>

<style></style>
